import { RiCloseLine, RiSave3Line } from '@remixicon/react'
import { Button, Input, message, Modal, Select } from 'antd'
import axios from 'axios'
import { usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useModalsState from '@/hooks/context/useModalsState'
import useAgents from '@/hooks/useAgents'

import { DocumentTag, DocumentType } from '@/constants/admin'
import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'

import { IAxiosError } from '@/types/api'
const { TextArea } = Input

const AddDatasetModal = () => {
  const [details, setDetails] = useState({
    document: '',
    title: '',
    content: '',
  })
  const [agent, setAgent] = useState<string | undefined>('')
  const [selectedTag, setSelectedTag] = useState<DocumentTag | undefined>()
  const { selectedAgent, agents } = useAgents()

  const { t } = useTranslation()
  const { openModal, toggleModal, reexecuteQuery } = useModalsState()
  const pathname = usePathname()

  useEffect(() => {
    setAgent(selectedAgent?.id)
  }, [selectedAgent])

  const add = async () => {
    message.loading(t('toast-loading'))
    const config = {
      method: 'post',
      withCredentials: true,
      ...configHeader,
      data: JSON.stringify({
        ...details,
        type: DocumentType.TXT,
        agent,
        tag: selectedTag,
      }),
    }

    try {
      await axios(`${API_URL}/v2/knowledge-base/section`, config)
      message.success(t('toast-new'))

      if (pathname === '/admin/documents') {
        reexecuteQuery.documents && reexecuteQuery.documents()
      }
      reexecuteQuery.raptor && reexecuteQuery.raptor()
      reexecuteQuery.knowledgeGraph && reexecuteQuery.knowledgeGraph()

      toggleModal('add-dataset')
      setDetails({ document: '', title: '', content: '' })
    } catch (error) {
      message.error(
        (error as IAxiosError).response?.data?.message ??
          t('toast-unknown-error')
      )
      console.error(error)
    }
  }

  return (
    <Modal
      centered
      open={openModal === 'add-dataset'}
      onCancel={() => toggleModal('add-dataset')}
      title='Add dataset'
      footer={null}
      width={800}
      className='rounded-lg border border-on-background/20 dark:border-dark-on-background/20'
    >
      <div className='flex flex-col gap-3'>
        <div className='flex flex-col gap-3 sm:flex-row'>
          <div className='flex w-full flex-col gap-1'>
            {t('document')}
            <Input
              showCount
              maxLength={150}
              value={details.document}
              onChange={(event) =>
                setDetails({ ...details, document: event.target.value })
              }
            />
          </div>
          <div className='flex w-full flex-col gap-1'>
            {t('title')}
            <Input
              showCount
              maxLength={150}
              value={details.title}
              onChange={(event) =>
                setDetails({ ...details, title: event.target.value })
              }
            />
          </div>
        </div>
        <div className=' mb-4 flex flex-col gap-1'>
          {t('content')}
          <TextArea
            showCount
            maxLength={8000}
            value={details.content}
            onChange={(event) =>
              setDetails({ ...details, content: event.target.value })
            }
            autoSize={{ maxRows: 15, minRows: 3 }}
          />
        </div>
        <div className='flex w-full flex-col gap-1'>
          {t('agent')}
          <Select
            value={agent}
            onChange={(value) => setAgent(value)}
            style={{ width: '100%' }}
            placeholder={t('agent-select-new')}
            options={agents?.map((a) => ({
              label: a.name,
              value: a.id,
            }))}
          />
        </div>
        <div className='flex w-full flex-col gap-1'>
          Tag
          <Select
            value={selectedTag}
            onChange={(value) => setSelectedTag(value)}
            style={{ width: '100%' }}
            options={Object.values(DocumentTag).map((tag) => ({
              label: tag,
              value: tag,
            }))}
          />
        </div>
      </div>
      <div className='mt-4 flex justify-end gap-2 text-xs'>
        <Button
          className='flex items-center px-3'
          icon={<RiSave3Line className='size-5' />}
          onClick={add}
        >
          {t('save')}
        </Button>
        <Button
          className='flex items-center px-3'
          icon={<RiCloseLine className='size-5' />}
          onClick={() => {
            toggleModal('add-dataset')
          }}
        >
          {t('cancel')}
        </Button>
      </div>
    </Modal>
  )
}

export default AddDatasetModal
