'use client'

import { memo, PropsWithChildren, useEffect, useMemo, useState } from 'react'

import { defaultTheme } from '@/branding-config'

import { ThemeContext } from './theme.context'
import { Theme } from './theme.types'

export const ThemeProvider = memo(({ children }: PropsWithChildren) => {
  const [theme, setTheme] = useState<Theme>(Theme.DARK)

  useEffect(() => {
    setTheme((localStorage.getItem('theme') as Theme) ?? defaultTheme)
  }, [])

  useEffect(() => {
    if (theme === Theme.DARK) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [theme])

  const switchTheme = () => {
    const newTheme = theme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT
    setTheme(newTheme)
    localStorage.setItem('theme', newTheme)
  }

  const contextValue = useMemo(() => {
    return {
      theme,
      switchTheme,
    }
  }, [theme, switchTheme])

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  )
})
