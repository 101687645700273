import { RiArticleLine, RiGlobalLine } from '@remixicon/react'
import { Button, Input, message, Segmented, Select } from 'antd'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useModalsState from '@/hooks/context/useModalsState'
import useAgents from '@/hooks/useAgents'

import { DocumentTag } from '@/constants/admin'
import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'
import { uploadDocuments } from '@/service/Chatbot'

import DragDropArea from './ImportBody/DragDropArea'
import InformationCircle from './ImportBody/InformationCircle'

interface ImportBodyProps {
  importToUserStorage?: boolean
}

const ImportBody: React.FC<ImportBodyProps> = ({ importToUserStorage }) => {
  const [formatValue, setFormatValue] = useState<string>('DOC')
  const [importing, setImporting] = useState(false)
  const [fileList, setFileList] = useState<FileList | null>(null)
  const [urlsToScrape, setUrlsToScrape] = useState('')
  const [agent, setAgent] = useState<string | undefined>()
  const { agents, selectedAgent } = useAgents()
  const [selectedTag, setSelectedTag] = useState<DocumentTag | undefined>(
    undefined
  )

  const { toggleModal, reexecuteQuery } = useModalsState()
  const { t } = useTranslation()

  useEffect(() => {
    if (selectedAgent && !agent) {
      setAgent(selectedAgent.id)
    }
  }, [selectedAgent])

  const handleUploadClick = async () => {
    setImporting(true)
    if (formatValue === 'WEB') {
      const config = {
        url: `${API_URL}/v2/knowledge-base/document/search-and-scrape`,
        method: 'post',
        withCredentials: true,
        ...configHeader,
        data: JSON.stringify({
          urls: urlsToScrape.replaceAll('\n', '~'),
          agent: agent,
          importToUserStorage,
          tag: selectedTag,
        }),
      }
      try {
        message.loading(t('toast-import-extracting'), 0)

        const res = await axios(config)

        if (!res.data.resolved) {
          message.error(t('toast-import-failure'))
        } else {
          reexecuteQuery.sections && reexecuteQuery.sections()
          reexecuteQuery.documents && reexecuteQuery.documents()
          reexecuteQuery.raptor && reexecuteQuery.raptor()
          reexecuteQuery.knowledgeGraph && reexecuteQuery.knowledgeGraph()

          message.success(t('toast-import-success'))

          if (importToUserStorage) {
            toggleModal('import-user-document')
          } else {
            toggleModal('filehub')
          }
        }
      } catch (error) {
        message.error(t('toast-import-failure'))
        console.error(error)
      }

      setImporting(false)
      return
    }

    if (!fileList) {
      setImporting(false)
      return
    }

    try {
      await uploadDocuments(
        fileList,
        agent,
        t,
        setFileList,
        importToUserStorage ?? false,
        selectedTag
      )
      reexecuteQuery.sections && reexecuteQuery.sections()
      reexecuteQuery.documents && reexecuteQuery.documents()
      reexecuteQuery.raptor && reexecuteQuery.raptor()
      reexecuteQuery.knowledgeGraph && reexecuteQuery.knowledgeGraph()

      if (importToUserStorage) {
        toggleModal('import-user-document')
      } else {
        toggleModal('filehub')
      }
    } catch (error) {
      message.error(t('toast-extract-failure'))
      console.error(error)
    }

    setImporting(false)
  }

  return (
    <div className='mt-4 flex flex-col justify-between gap-5'>
      <div className='flex flex-col items-start gap-1'>
        <div className='text-sm'>Format</div>
        <Segmented
          options={[
            {
              label: 'DOC',
              value: 'DOC',
              icon: <RiArticleLine />,
            },
            {
              label: 'WEB',
              value: 'WEB',
              icon: <RiGlobalLine />,
            },
          ]}
          value={formatValue}
          onChange={(value) => setFormatValue(value)}
        />
      </div>
      {formatValue === 'WEB' ? (
        <Input.TextArea
          value={urlsToScrape}
          onChange={(event) => setUrlsToScrape(event.target.value)}
          placeholder='URLs separated by new line'
          rows={4}
        />
      ) : (
        <DragDropArea setFileList={setFileList} />
      )}
      {!importToUserStorage && (
        <div className='flex items-center justify-between gap-2'>
          <div className='flex gap-1 whitespace-nowrap'>
            <div className='text-admin-accent flex text-sm'>{t('agent')}</div>
            <InformationCircle text={t('agent-select-info')} />
          </div>
          <Select
            className='w-1/2'
            value={agent}
            onChange={(val) => setAgent(val)}
            placeholder={t('agent-select-new')}
            options={agents?.map((a) => ({
              label: a.name,
              value: a.id,
            }))}
          />
        </div>
      )}

      <div className='flex items-center justify-between gap-2'>
        <div className='flex gap-1 whitespace-nowrap'>
          <div className='text-admin-accent flex text-sm'>Tag</div>
          <InformationCircle text={t('document-tag')} />
        </div>
        <Select
          className='w-1/2'
          value={selectedTag}
          onChange={(val) => setSelectedTag(val)}
          options={Object.values(DocumentTag).map((tag) => ({
            label: tag,
            value: tag,
          }))}
        />
      </div>

      <Button
        loading={importing}
        onClick={handleUploadClick}
        disabled={
          ((!fileList || !fileList.length) &&
            (formatValue !== 'WEB' || urlsToScrape === '')) ||
          importing
        }
      >
        {importing ? t('importing-button') : t('import-button')}
      </Button>
    </div>
  )
}

export default ImportBody
