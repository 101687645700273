'use client'

import { Tour, TourProps } from 'antd'
import { memo, PropsWithChildren, useMemo, useRef, useState } from 'react'

import { colors } from '@/branding-config'
import { COMPANY } from '@/constants/env'

import { TourContext } from './tour.context'

export const TourProvider = memo(({ children }: PropsWithChildren) => {
  const [openTour, setOpenTour] = useState<'userTour' | 'userBackofficeTour'>()
  const [tourFinished, setTourFinished] = useState<{
    userTour: boolean
    userBackofficeTour: boolean
  }>({
    userTour: false,
    userBackofficeTour: false,
  })
  const [tourSteps, setTourSteps] = useState<TourProps['steps']>([])
  const userTourRef1 = useRef(null)
  const userTourRef2 = useRef(null)
  const userTourRef3 = useRef(null)
  const userTourRef4 = useRef(null)

  const backofficeTourRef1 = useRef(null)
  // const backofficeTourRef2 = useRef(null)
  const backofficeTourRef3 = useRef(null)
  const backofficeTourRef4 = useRef(null)
  const backofficeTourRef5 = useRef(null)

  const userTourSteps: TourProps['steps'] = [
    {
      title: `Welcome to ${COMPANY}!`,
      description: `Let's start with a quick tour of the platform.`,
    },
    {
      title: 'Interact with the agent',
      description:
        'Ask your question here. You can also leave any feedback by typing it here.',
      target: () => userTourRef1.current,
    },
    {
      title: 'Upload files',
      description:
        'Drag and drop files in the input box or click this icon to upload files.',
      target: () => userTourRef2.current,
    },
    // {
    //   title: 'Settings',
    //   description:
    //     'Click here to change the settings of the agent. You can switch between GPT-3 and GPT-4 models, enable/disable streaming and drafting, or select preferred language.',
    //   target: () => userTourRef3.current,
    // },
    {
      title: 'Dashboard',
      description:
        'Click here to access the dashboard. Here you can upload your documents and view how this information is organized.',
      target: () => userTourRef4.current,
    },
  ]

  const userBackofficeTourSteps: TourProps['steps'] = [
    {
      title: `Welcome to ${COMPANY} dashboard!`,
      description: `Let's start with a quick tour of the dashboard.`,
    },
    {
      title: 'Neural graph',
      description:
        'The Neural graph is a visual map that shows how different documents are linked or related to each other. Imagine it as a spider web, where each thread connects one document to another, helping you see how information is interconnected.',
      target: () => backofficeTourRef1.current,
    },
    // {
    //   title: 'Knowledge Graph',
    //   description:
    //     'A knowledge graph is like a giant, interactive library in a visual format. It connects pieces of information, showing how different facts, ideas, or concepts are related to each other, making it easier to understand and discover new knowledge.',
    //   target: () => backofficeTourRef2.current,
    // },
    {
      title: 'Documents Board',
      description:
        'Here you can see where your knowledge is stored in your neural graph & delete memories.',
      target: () => backofficeTourRef3.current,
    },
    {
      title: 'Add knowledge',
      description:
        'Click here to add knowledge. You can import PDF, DOCX or spreadsheet files, or simply paste the url of the website you wish to add to your stored memories.',
      target: () => backofficeTourRef4.current,
    },
    {
      title: 'Navigation',
      description:
        'Click here to see your conversation logs or open the chatbot where you can interact with the agent.',
      target: () => backofficeTourRef5.current,
    },
  ]

  const startTour = (id: 'userTour' | 'userBackofficeTour') => {
    setTourSteps(userTourSteps)
    const tourFinishedCookie = localStorage.getItem(`${id}Finished`)
    if (!tourFinishedCookie || tourFinishedCookie !== 'true') {
      setOpenTour(id)
    }
  }

  const finishTour = (id: 'userTour' | 'userBackofficeTour') => {
    setOpenTour(undefined)
    localStorage.setItem(`${id}Finished`, 'true')
    setTourFinished({
      userBackofficeTour:
        id === 'userBackofficeTour' || tourFinished.userBackofficeTour,
      userTour: id === 'userTour' || tourFinished.userTour,
    })
  }

  const contextValue = useMemo(() => {
    return {
      userTourRef1,
      userTourRef2,
      userTourRef3,
      userTourRef4,
      backofficeTourRef1,
      // backofficeTourRef2,
      backofficeTourRef3,
      backofficeTourRef4,
      backofficeTourRef5,
      tourSteps: tourSteps ?? [],
      openTour,
      startTour,
      tourFinished,
    }
  }, [
    userTourRef1,
    userTourRef2,
    userTourRef3,
    userTourRef4,
    backofficeTourRef1,
    // backofficeTourRef2,
    backofficeTourRef3,
    backofficeTourRef4,
    backofficeTourRef5,
    tourSteps,
    openTour,
    tourFinished,
  ])

  return (
    <TourContext.Provider value={contextValue}>
      {children}
      <Tour
        open={openTour === 'userTour'}
        onClose={() => finishTour('userTour')}
        steps={userTourSteps}
        onFinish={() => finishTour('userTour')}
        mask={{
          style: {
            boxShadow: 'inset 0 0 15px #333',
          },
          color: `${colors.dark.background}DD`,
        }}
      />
      <Tour
        open={openTour === 'userBackofficeTour'}
        onClose={() => finishTour('userBackofficeTour')}
        steps={userBackofficeTourSteps}
        onFinish={() => finishTour('userBackofficeTour')}
        mask={{
          style: {
            boxShadow: 'inset 0 0 15px #333',
          },
          color: `${colors.dark.background}DD`,
        }}
      />
    </TourContext.Provider>
  )
})
